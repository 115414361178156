import React from 'react';
import { Container, AppCard, Title, Row, ArrowRight } from './styles';

export const HomeTemplate = () => {
  return (
    <Container>
      <AppCard>
        <Title>Applications</Title>
        <Row target="_blank" href="/port-dues/">
          <div>Port Dues</div>
          <ArrowRight />
        </Row>
      </AppCard>
    </Container>
  );
};
