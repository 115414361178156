import React from 'react';

export const ArrowRightIcon = ({ className }: IconProps) => {
  return (
    <svg
      id="Laag_2"
      data-name="Laag 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      className={className}
    >
      <path d="M12.18,28.84A2,2,0,0,1,11,28.43a2,2,0,0,1-.38-2.81l8.23-10.78L10.53,4.06A2,2,0,1,1,13.7,1.62L23.86,14.83,13.77,28.05A2,2,0,0,1,12.18,28.84Z" />
    </svg>
  );
};

export interface IconProps {
  className?: string;
}
