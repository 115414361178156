import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { HomeTemplate } from '../templates/HomeTemplate';

const IndexPage = () => (
  <Layout>
    <SEO title="PortInsider" />
    <HomeTemplate />
  </Layout>
);

export default IndexPage;
