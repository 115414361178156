import React from 'react';
import { Paper, Content } from './styles';

export const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <Paper className={className}>
      <Content>{children}</Content>
    </Paper>
  );
};

export interface CardProps {
  className?: string;
}
