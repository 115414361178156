import styled from 'styled-components/macro';
import { Card } from '../../components/Card';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import { Link } from 'gatsby';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

export const AppCard = styled(Card)`
  width: 100%;
  height: 25rem;

  @media screen and (min-width: 992px) {
    width: 50%;
  }
`;

export const Row = styled.a`
  padding: 0.75rem 0.25rem;
  border-bottom: 1px solid rgb(224, 224, 224);
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: #f9f9f9;
  }
`;

export const Title = styled.h2`
  margin: 0 0 1rem 0;
`;

export const ArrowRight = styled(ArrowRightIcon)`
  height: 13px;
  fill: #0693ca;
`;
